<template>
        <div class="d-flex flex-nowrap" :class="class" v-bind="$attrs">
            
            <template v-if="couldSaveCurrentLayout && isDirty">
                <div v-if="canSetDefaults && activeLayout?.id && activeLayout.isDefault" class="dropdown" style="display: contents;">
                    <button class="btn btn-sm btn-link p-0 ps-1"
                        :disabled="!isDirty || activeLayout?.isSaving"
                        data-bs-toggle="dropdown"
                        :title="$t('Save layout changes')">
                        <i class="bi bi-check-lg icon-bold"></i>
                    </button>
                    <ul class="dropdown-menu">
                        <li><button @click="() => save(true)" class="dropdown-item">{{ $t('Save Changes') }}</button></li>
                        <li><button @click="() => save()" class="dropdown-item">{{ $t('Save As New ') }}</button></li>
                    </ul>
                </div>
                <button v-else class="btn btn-sm btn-link p-0 ps-1"
                    @click="() => save()"
                    :disabled="!isDirty || activeLayout?.isSaving"
                    :title="$t('Save layout changes')">
                    <i class="bi bi-check-lg icon-bold"></i>
                </button>
            </template>
            <ODataLookup :dataObject="dsLayouts" :gridOptions="gridOptions" disablePinned disableRecent :bind="setLayout" :bindClear="() => resetLayout()">
                <template #target="{target}">
                    <button :ref="target" class="dropdown-toggle btn btn-sm btn-link text-truncate">
                        {{activeLayoutName}}
                    </button>
                </template>
                <OColumn colId="layoutName" field="Name" width="300"
                    :cellTitle="row => row.Name">
                    <template #default="{row}">
                        <i :class="getLayoutTypeIcon(row)"></i>
                        {{ row.Default ? $t('Default') : row.Name }}
                    </template>
                </OColumn>
                <OColumn field="OrgUnit" width="250">
                </OColumn>
            </ODataLookup>
            <!-- <template v-if="dataGridControl && activeLayout?.id"> -->
            <button v-if ="isDirty" class="btn btn-sm btn-link text-danger" @click="cancelChanges"
                :title="$t('Cancel layout changes')">
                <i class="bi bi-arrow-counterclockwise"></i>
            </button>

                <!-- <button class="btn btn-sm btn-link text-danger" @click="resetLayout" -->
                    <!-- :title="$t('Reset current layout')"> -->
                    <!-- <i class="bi bi-x-circle-fill"></i> -->
                <!-- </button> -->
                <!-- <button class="btn btn-sm btn-link ms-auto" @click="dataGridControl.dataObject.layoutManager.lockLayout = !dataGridControl.dataObject.layoutManager.lockLayout" -->
                    <!-- :title="dataGridControl.dataObject.layoutManager.lockLayout --> 
                        <!-- ? $t('Unlock layout saving. Layout will autosave on grid property changes') --> 
                        <!-- : $t('Lock layout saving. Layout will stop autosaving on grid property changes')"> -->
                    <!-- <i :class="dataGridControl.dataObject.layoutManager.lockLayout ? 'bi bi-lock-fill' : 'bi bi-unlock-fill'"></i> -->
                <!-- </button> -->
            <!-- </template> -->

            <button v-if="activeLayout && !activeLayout.isDefault" class="btn btn-sm btn-link" @click="resetLayout"
                    :title="$t('Reset to default layout')">
                    {{ $t('Reset To Default') }}
            </button>
        </div>
</template>

<script setup lang="ts">
import type { Ref } from 'vue';

import { dataGridControlKey } from 'o365.modules.vue.injectionKeys.js';
import { LayoutType } from 'o365.modules.DataObject.Layout.ts';
// import vTooltip from 'o365.vue.directive.tooltip.ts';
import { inject, computed, ref } from 'vue';
import $t from 'o365.modules.translate.ts';
import { app, userSession } from 'o365.modules.configs.ts';
import { getOrCreateDataObject } from 'o365.vue.ts';

export interface IProps {
    class?: any,
};

const props = defineProps<IProps>();

const gridOptions = {
    noRowsFound: $t('No layouts found...')
};

const dataGridControl = inject<Ref<any>|null>(dataGridControlKey, null);

const activeLayout = computed(() => {
    return dataGridControl?.value?.dataObject.layoutManager?.hasActiveLayout ? dataGridControl?.value?.dataObject.layoutManager.activeLayout : null;
});

const isDirty = computed(() => {
    const layoutManager = dataGridControl.value.dataObject && dataGridControl.value.dataObject?.layoutManager;
    if (layoutManager == null || layoutManager.isSaving || !layoutManager.canSave) {
        return false;
    }
    const activeLayout = layoutManager.hasActiveLayout
        ? dataGridControl.value.dataObject?.layoutManager.activeLayout
        : null;
    return activeLayout && activeLayout.hasChanges();
});

const couldSaveCurrentLayout = computed(() => {
    if (activeLayout.value) {
        if (LayoutType.SharedFromPerson == activeLayout.value.layoutType) {
            return false;
        } else if ([LayoutType.SharedDefault, LayoutType.Shared, LayoutType.SharedPersonalDefault].includes(activeLayout.value.layoutType)) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
});

const activeLayoutName = computed(() => {
    if (!activeLayout.value?.id) {
        return $t('Not set');
    }
    return activeLayout.value?.isDefault ? $t('Default') : (activeLayout.value?.name ?? $t('Not set'));
});

const canSetDefaults = ref(false);
try {
    SharingCapabilitiesChecker.getInstance().canSetDefaultLayouts().then(value => {
        canSetDefaults.value = value;
    });

} catch (ex) {
    canSetDefaults.value = false;
}

const getFields = () => {
    const fields = ['ID', 'Name', 'Description', 'DataObject_ID', 'App_ID', 'Person_ID', 'OrgUnit_ID', 'Updated', 'AccessIdPath', 'OrgUnit', 'Hidden']
        .map(field => ({
            name: field,
        }));
    fields.push({ name: 'Default', sortOrder: 1, sortDirection: 'desc' });
    return fields;
};
const configurableRegister = { isConfigured: false, id: undefined};
const registerClause = configurableRegister.isConfigured ? `[Register_ID] = ${configurableRegister.id}` : '[Register_ID] IS NULL';

const whereClauses = [
    `[DataObject_ID] = '${dataGridControl?.value.dataObject.id}'`,
    `[App_ID] = '${app.id}'`,
    `[Hidden] = 0`,
    registerClause,
    ];

const dsLayouts = getOrCreateDataObject({
    id: `o_${dataGridControl?.value.dataObject.id}_layoutsActionnsList`,
    viewName: 'sviw_O365_MyLayouts2',
    uniqueTable: 'stbv_O365_Layouts',
    fields: getFields(),
    disableLayouts: true,
    allowDelete: true,
    allowUpdate: true,
    whereClause: whereClauses.join(' AND ')
});

function setLayout(layout) {
    const id = layout.ID ?? layout.Layout_ID;
    const activeId =   dataGridControl?.value.dataObject.layoutManager.activeLayout.id;
    if (id === activeId) { return; }
    let options = dsLayouts.recordSource.getOptions();;
    let handler = dsLayouts.dataHandler;
    options.whereClause += ` AND [ID] = ${layout.ID}`;
    options.fields!.push({name: 'Layout'});
    options.filterString = '';
    return handler.request('retrieve', options).then(result => {
        let layout = result[0];            
        dataGridControl?.value.dataObject.layoutManager?.applyLayout(layout, true);
    });
}

function resetLayout() {
    dataGridControl?.value?.dataObject.layoutManager?.resetLayout();
}

function cancelChanges() {
    dataGridControl?.value?.dataObject.layoutManager?.reapplyLayout(true, true);
} 

function getLayoutTypeIcon(pLayout: any) {
    if (pLayout.Person_ID == userSession.personId) {
        // Person saved layout
        return 'bi bi-person invisible'
    } else if (pLayout.Person_ID) {
        // Shared from person
        return 'bi bi-person'
    } else if (pLayout.OrgUnit_ID && !pLayout.Default) {
        // Context layout
        return 'bi bi-share';
    } else if (pLayout.Default) {
        // Context default layout
        return 'bi bi-play-circle';
    }
}

function asyncConfirm(...args: any) {
    return import('o365.controls.confirm.ts').then((services) => services.default(...args));
}

function asyncAlert(...args: any) {
    return import('o365.controls.alert.ts').then((services) => services.default(...args));
}

async function saveAsNew() {
    try {
        const result = await asyncConfirm({
            message: $t('Name'),
            title: $t('Save Layout'),
            btnTextOk: $t('Save'),
            btnTextCancel: $t('Cancel'),
            textInputRequired: true
        });
        const name = result.TextInput;
        if (!name) { 
            throw new Error($t("Layout name can't be empty"));
        }
        await dataGridControl?.value.dataObject.layoutManager.saveLayout({
            saveAsNew: true,
            newWithChanges: true,
            name: name
        });
        // dsLayouts.load();
        import('o365.vue.ts').then(m => {
            const store = m.dataObjectStores;
            const dataObject = store?.get('site')?.get(`o_${dataGridControl.value.dataObject.id}_layoutsList`);
            if (dataObject?.value) { dataObject.value.load(); }
        });
    } catch (ex) {
        if (!ex.Canceled) {
            asyncAlert(ex.message);
        }
    }
}

function save(pSkipSaveAsNewCheck = false) {
        if (!pSkipSaveAsNewCheck && activeLayout.value && (!activeLayout.value.id || activeLayout.value.isDefault)) {
        saveAsNew();
        return;
    }
    dataGridControl?.value.dataObject.layoutManager.saveLayout({
        includedModules: dataGridControl.value.dataObject.layoutManager.registeredModules
    });
}
</script>